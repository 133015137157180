import 'owl.carousel';

jQuery( document ).ready( function ( $ ) {
	/*const owl = $( '.owl-carousel' ).owlCarousel( {
		loop: true,
		dots: false,
		nav: true,
		autoWidth: true,
		margin: 45,
		items: 5,
		navText: [
			'<i class="fal fa-angle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fal fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		],
		responsive: {
			0: {
				items: 1,
				margin: 0,
				autoWidth: false
			},
			768: {
				items: 2,
				margin: 45
			},
			992: {
				items: 3
			},
			1200: {
				items: 5
			}
		}
	} );*/

	/*owl.on( 'changed.owl.carousel', function ( event ) {
	const active_items = $( '.owl-item.active' );
	const active_item = $( active_items[0] );
	const target = active_item.find( '.track-title>.lyrics-toggle' ).attr( 'data-target' );
	$( '#lyrics>div' ).removeClass( 'active' );
	$( '#lyrics>div' + target ).addClass( 'active' );
} );*/

	const owl = $( '.owl-carousel.mobile-carousel' ).owlCarousel( {
		loop: true,
		dots: false,
		nav: true,
		items: 1,
		margin: 0,
		autoWidth: false,
		navText: [
			'<i class="fal fa-angle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fal fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );

	owl.on( 'translated.owl.carousel', function ( event ) {
		const active_items = $( '.owl-item.active' );
		const active_item = $( active_items[0] );
		const target = active_item.find( '.track-title>.lyrics-toggle' ).attr( 'data-target' );
		$( '#lyrics>div' ).removeClass( 'active' );
		$( '#lyrics>div' + target ).addClass( 'active' );
	} );


	const owl2 = $( '.owl-carousel.desktop-carousel' ).owlCarousel( {
		loop: true,
		dots: false,
		nav: true,
		items: 1,
		margin: 0,
		autoWidth: false,
		navText: [
			'<i class="fal fa-angle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fal fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );

	owl2.on( 'changed.owl.carousel', function ( event ) {
		$( '#lyrics>div' ).removeClass( 'active' );
		$( '#lyrics' ).fadeOut();
	} );

	$( '.play-pause a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).find( 'img' ).toggle();

		const audios = $( '.play-pause  audio' );
		const audio = $( this ).next( 'audio' )[0];

		audios.each( function ( index, item ) {
			if ( $( item )[0] !== audio ) {
				$( item )[0].pause();
				$( item ).prev( 'a' ).find( 'img:last-child' ).hide();
				$( item ).prev( 'a' ).find( 'img:first-child' ).show();
			}
		} );

		if ( audio.paused ) {
			audio.play();
		} else {
			audio.pause();
		}
	} );

	$( '.lyrics-toggle' ).on( 'click', function ( e ) {
		e.preventDefault();
		const target = $( this ).attr( 'data-target' );
		$( '#lyrics>div' ).removeClass( 'active' );
		$( '#lyrics>div' + target ).addClass( 'active' );
		$( '#lyrics' ).fadeIn();
	} );
} );